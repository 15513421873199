.profiles-list{
    width: 95%;
    margin: 0 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgb(143, 142, 142, .5);
    h2{
        font-size: 24px;
        font-weight: 400;
        opacity: .9;
        margin-left: 15px;
    }
    button{
        width: 200px;
        height: 40px;
        padding: 0;
        margin-right: 15px;
    }
    
    
}

.space{
    height: 60px;
    width: 100%;
}

