.access-form {
    .row {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .col {
            width: 48%;
        }
        .segment {
            border: 1px solid #e7e7e8;
            border-radius: 5px;
            margin-bottom: 20px;
            &-title {
                min-height: 40px;
                background-color: #e73c37;
                display: flex;
                align-items: center;
                font-weight: bold;
                letter-spacing: 1px;
                padding: 10px;
                color: white;
            }
            &-content {
                min-height: 200px;
                padding: 30px 10px;
                &.actions {
                    min-height: unset;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    .Button {
                        margin: 10px;
                    }
                }
                .Input, .Checkbox, .Select, .Date-Picker {
                    width: 100%;
                    label {
                        text-transform: uppercase;
                    }
                }
                .link-label {
                    font-size: 14px;
                    font-weight: bold;
                    margin: 20px 0 10px;  
                }
                a {
                    color: blue;
                    display: flex;
                    align-items: center;
                    &:hover {
                       opacity: 0.8;
                    }
                    svg {
                        min-width: 20px;
                        margin-right: 5px;
                    }
                }
            }
        }
    }
}

.sms-form{
    display: flex;
    flex-direction: column;
    margin: 10px 0;
    cursor: pointer;
    
    .link-label{
        color: black;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 14px;
    }
    .Input{
        flex-direction: row;
        color: blue;
        svg{
            color: black;
            margin-right: 5px;
        }
        &:hover{
            color: rgb(60, 60, 223);
        }
    }
}