.Rewards {
    .Reward {
        &-Form {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: flex-start;
            @media (max-width: 767px) {
                flex-direction: column;
            }
            .Input, .Date-Picker, .Select, .Textarea {
                width: 49%;
                @media (max-width: 767px) {
                    width: 100%;
                }
            }
        }
        &-Bottom {
            display: flex;
            justify-content: flex-end;
            margin: 20px 0;
        }
     

    }
}