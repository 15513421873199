.Game {
    margin: 15px auto;
    width: 95%;
    color: #333;
    &-Header {
        min-height: 50px;
        font-size: 24px;
        padding: 0 15px;
        border-bottom: 1px solid #dcddde;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        @media (max-width: 767px) {
            padding: 0;
            font-size: 18px;
        }
    }
}

.result {
    color: #333;
    &-label {
        font-weight: bold;
    }
    &-value {
        border-bottom: 1px solid lightgray;
        padding-bottom: 10px;
        display: flex;
        align-items: center;
        min-height: 60px;
        img {
            width: 50px;
            margin-right: 10px;
        }
    }
    &-controls {
        margin-top: 30px;
        display: flex;
        justify-content: flex-end;
    }
}

.set-result {
    .set-result-controls {
        margin-top: 30px;
        display: flex;
        justify-content: flex-end;
        button {
            &:first-child {
                margin-right: 20px;
            }
        }
    }
}