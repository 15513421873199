.input-wrapper {
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    position: relative;
}

.color-preview {
    width: 50px;
    height: 25px;
    margin-right: 20px;
    border: 1px solid black;
    border-radius: 5px;
}

.color-picker_wrapper {
    position: absolute;
    left: 65px;
    z-index: 10;
}
