.Game-SetUp {
    .controls {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        .id {
            flex: 1;
            opacity: .6;
        }
        .Button {
            margin-left: 10px;
        }
    }
    .game-type {
        font-weight: 600;
        margin-bottom:  20px;
        color: black;
        display: flex;
        align-items: center;
        img{
            width: 55px;
            height: 55px;
            margin: 0 0 0 15px;
        }
        span {
            margin-left: 15px;
            font-weight: normal;
            opacity: .6;
        }
    }
    .row {
        display: flex;
        align-items: center;
        .Date-Picker {
            flex: 1;
        }
        .Button {
            margin-left: 30px;
            width: 130px;
            @media (max-width: 767px) {
                width: 100px;
            }
        }
    }
}