.Dropdown {
    background-color: #e73c37;
    border: none;
    border-radius: 3px;
    color: white;
    font-size: 16px;
    height: 40px;
    letter-spacing: 1px;
    padding: 10px 15px;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    &:hover {
        background-color: #dc393f;
    }
    &.show {
        overflow: visible;
    }
    &.disabled {
        opacity: 0.5;
        cursor: auto;
        &:hover {
            background-color: #e73c37;
        }
    }
    &-Label {
        display: flex;
        align-items: center;
        min-width: 100px;
        span {
            flex: 1;
        }
    }
    &-Menu {
        position: absolute;
        z-index: 10;
        top: 42px;
        right: 0;
        color: #333333;
        width: 100%;
        background-color: white;
        box-shadow: 1px 1px 5px lightgrey;
        border-radius: 3px;
        .option {
            width: 100%;
            padding: 10px;
            display: flex;
            align-items: center;
            overflow: hidden;
            &:hover {
                background-color: #fbe9ea;
            }
            &.disabled {
                opacity: 0.5;
                &:hover {
                    background-color: white;
                    cursor: auto;
                }
            }
            .icon {
                display: inline-block;
                width: 25px;
                min-width: 25px;
            }
            .name {
                overflow: hidden !important;
                text-overflow: ellipsis;
            }
        }
    }
}
