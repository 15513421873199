.Input {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    color: #262626;
    font-size: 16px;
    position: relative;
    @media (max-width: 767px) {
        margin-bottom: 5px;
        font-size: 14px;
    }

    .centered-label,.label {
        font-weight: bold;
        font-size: 14px;
        text-transform: uppercase;
    }
    .centered-label{
        text-align: center;
    }
    input {
        background-color: transparent;
        border: 1px solid gray;
        color: #262626;
        border-radius: 3px;
        width: 100%;
        height: 35px;
        padding: 0 10px;
        font-size: 16px;
        letter-spacing: 1px;
        transition: all 0.4s ease-in-out;
        appearance: none;
        &:disabled,  &:read-only {
            opacity: 0.6;
            border-color: transparent;
            border-bottom: 1px solid gray;
        }
    }
    .error-msg {
        height: 10px;
        font-size: 12px;
        color: #e73c37;
        font-weight: bold;
        margin: 3px 0 0;
        p {
          margin: 0;
        }
    }
    .icon {
        position: absolute;
        right: 15px;
        top: 25px;
        svg {
            width: 20px;
            opacity: 0.8;
        }
    }
  }