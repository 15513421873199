.Create-Game {
    margin: 15px auto;
    width: 95%;
    color: #333;
    &-Header {
        min-height: 50px;
        font-size: 24px;
        padding: 0 15px;
        border-bottom: 1px solid #dcddde;
        display: flex;
        align-items: center;
        @media (max-width: 767px) {
            padding: 0;
            font-size: 18px;
        }
    }
    &-Content {
        min-height: 300px;
        margin: 20px 0;
        border: 1px solid #dcddde;
        padding: 15px 15px 70px 15px;
        border-radius: 5px;
        position: relative;
        .title {
            font-size: 24px;
            font-weight: bold;
            letter-spacing: 2px;
            margin: 0 0 20px 0;
        }
        .controls {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            display: flex;
            justify-content: flex-end;
            padding: 15px;
            button {
                margin: 0 0 0 10px;
                width: 100px;
            }
        }
    }
}