.pass-form {
    .row {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .col {
            width: 48%;
        }
        .segment {
            border: 1px solid #e7e7e8;
            border-radius: 5px;
            margin-bottom: 20px;
            &-title {
                min-height: 40px;
                background-color: #e73c37;
                display: flex;
                align-items: center;
                font-weight: bold;
                letter-spacing: 1px;
                padding: 10px;
                color: white;
            }
            &-content {
                min-height: 200px;
                padding: 30px 10px;
                &.actions {
                    min-height: unset;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    .Button {
                        margin: 10px;
                    }
                }
                .Input,
                .Checkbox,
                .Select {
                    width: 100%;
                    label {
                        text-transform: uppercase;
                    }
                }
                .link-label {
                    font-size: 14px;
                    font-weight: bold;
                    margin: 20px 0 10px;
                }
                a {
                    color: blue;
                    display: flex;
                    align-items: center;
                    &:hover {
                        opacity: 0.8;
                    }
                    svg {
                        min-width: 20px;
                        margin-right: 5px;
                    }
                }
            }
        }
    }
}

.eticket-number {
    cursor: pointer;
    margin-left: 20px;
    color: blue;
    font-style: italic;
    &:hover {
        text-decoration: underline;
    }
}

.user_link{
    color: blue;
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover{
        text-decoration: underline;
    }
}

.showrate-section{
    margin-top: 25px;
    p{
        margin: 3px 0;
        padding: 0;
        span{
            font-weight: bold;
        }
    }
}