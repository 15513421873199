.voucher-type {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    .details {
        width: 100%;
    }
    p {
        margin: 0;
        font-weight: bold;
        span {
            font-weight: normal;
        }
        a {
            color: blue;
            &:hover {
                opacity: 0.8;
            }
            svg {
                min-width: 20px;
            }
        }
    }
}

.select-reward-preview {
    width: 40px;
    height: 40px;
}
