
.suggestion-wrapper {
    position: absolute;
    top: 60px;
    width: 100%;
    z-index: 40;
    .tex{
        border-bottom: 1px solid rgb(167, 163, 163);
        border-left: 1px solid rgb(167, 163, 163);
        border-right: 1px solid rgb(167, 163, 163);
        background-color: rgba(252, 252, 252, 0.952);
        padding: 10px 5px;
        cursor: pointer;
    
    }
}