.Questions {
    .Question {
        &-Bottom {
            display: flex;
            justify-content: flex-end;
            margin: 20px 0;
        }
        &-Form {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: flex-start;
            @media (max-width: 767px) {
                flex-direction: column;
            }
            .Input, .Textarea {
                width: 49%;
                @media (max-width: 767px) {
                    width: 100%;
                }
            }
        }
        .Table {
            width: 100%;
        }
        .Answer {
            margin: 10px 0;
            padding: 10px;
            &-Form {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                .Input, .Textarea {
                    width: 49%;
                    @media (max-width: 767px) {
                        width: 100%;
                    }
                }
            }
            .Quote {
                border-top: 1px solid #dcddde;
                margin: 20px 0;
                padding: 20px 0;
                &-Header {
                    display: flex;
                    align-items: center;
                    margin: 0 0 20px;
                    p {
                        font-size: 20px;
                        font-weight: bold;
                        flex: 1;
                        margin: 0;
                        color: #333;
                    }
                    
                }
            }
            &-Controls {
                display: flex;
                justify-content: flex-end;
                margin-top: 10px;
                button {
                    margin: 0 0 0 10px;
                }
            }
        }
        .player-answers {
            color: #333;
            .header {
                height: 50px;
                font-size: 24px;
                padding: 0 15px;
                border-bottom: 1px solid #dcddde;
                display: flex;
            }
            h3 {
                font-weight: normal;
                margin: 0;
                flex: 1;
            }
            .player {
                display: flex;
                align-items: center;
                border-bottom: 1px solid #dcddde;
                padding: 5px 15px;
                cursor: pointer;
                height: 70px;
                &:hover {
                    background-color: #fbe9ea;
                }
                .name {
                    flex: 1;
                }
                .image {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    img {
                        height: 50px;
                    }
                }
                .checked{
                    width: 20px;
                    font-size: 16px;
                    @media (max-width: 768px) {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}