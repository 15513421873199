.Event {
    position: relative;
    background-color: transparent;
    margin: 15px auto;
    width: 95%;
    color: #333;
    &-Header {
        min-height: 50px;
        font-size: 24px;
        padding: 10px 15px;
        border-bottom: 1px solid #dcddde;
        display: flex;
        align-items: center;
        &.mb {
            margin-bottom: 20px;
        }
        @media (max-width: 767px) {
            padding: 0;
            font-size: 18px;
        }
        .controls {
            display: flex;
            justify-content: flex-end;
        }
        .Button {
            margin-left: 10px;
        }
    }
    &-Content {
        min-height: 700px;
        padding: 0 15px 20px;
        border: 1px solid lightgray;
        border-top: none;
        @media (max-width: 767px) {
            padding: 10px 0;
        }
        form {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: flex-start;
            @media (max-width: 767px) {
                flex-direction: column;
            }
            .Input,
            .Date-Picker,
            .Select,
            .Checkbox,
            .Textarea {
                width: 49%;
                @media (max-width: 767px) {
                    width: 100%;
                }
            }
            .Checkbox{
                display: flex;
                align-items: center;
                height: 64px;
            }
        }
        .controls {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin: 0 0 20px;
            padding-top: 20px;
            button {
                &:first-child {
                    margin-right: 10px;
                }
            }
        }
    }
    .remove {
        color: red;
        cursor: pointer;
    }

    .check {
        border: 1px solid gray;
        font-size: 18px;
        padding: 1px;
        width: 20px;
        height: 20px;
        border-radius: 3px;
    }

    .block {
        width: 100%;
    }
}

.no-data {
    margin: 15px auto;
    width: 95%;
    font-style: italic;
}
