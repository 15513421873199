.Tabs {
    display: flex;
    width: 100%;
    .Tab {
        min-height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        border: 1px solid #dcddde;
        border-bottom: none;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        background-color: transparent;
        color: #333;
        cursor: pointer;
        font-weight: bold;
        letter-spacing: 1px;
        padding: 10px 20px;
        &:hover {
            background-color: #fbe9ea;
            border-color: transparent;
        }
        &.selected {
            background-color: #e73c37;
            color: white;
            cursor: auto;
            border-color: transparent;
        }
        @media (max-width: 767px) {
            min-height: 35px;
            max-width: 100px;
            font-size: 14px;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }
        p {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 0;
        }
      
    }
   
}
.half{
    width: 50%;
}
.Tab-Panel {
    padding: 15px;
    width: 100%;
    min-height: 100vh;
    border: 1px solid lightgray;
}

.tab-icon{
    width: 20px;
    height: 20px;
    margin-right: 10px;
    display: flex;
}