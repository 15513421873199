.loader-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 500px;
}

.comparing-container{
    &.wrapper{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &.A,&.B{
        display: flex;
        flex-direction: column;
        width:45%;
        
    }
   
}