.tab-controls {
    position: relative;
    .Select {
        min-width: 217px;
        margin-right: 30px;
        @media (max-width: 767px) {
            margin-right: 0;
        }
    }
    .Input {
        min-width: 200px;
        margin-right: 30px;
        @media (max-width: 767px) {
            margin-right: 0;
        }
        input {
         height: 38px;
        }
    }
    .btn-wrapper{
        .Button{
            position: absolute;
            right: 15px;
            margin-top: 15px;
            @media (max-width: 767px) {
                position: relative;
                margin-left: 15px;
                font-size: 14px;
            }
        }
    }
}
.btn_container{
    display: flex;
    float: right;
    button{
        margin-left: 5px;
        background-color: #808080;
    }
}