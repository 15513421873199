.Modal-Wrapper {
    position: fixed;
    inset: 0;
    z-index: 110;
    background-color: rgba(51, 51, 51, 0.7);
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    overflow-y: scroll;
    animation: fadeIn .3s;
    -webkit-animation: fadeIn .3s;
    -moz-animation: fadeIn .3s;
    .Modal {
        background-color: white;
        width: 90%;
        margin: auto;
        border-radius: 3px;
        min-height: unset;
        @media (max-width: 767px) {
            width: 100%;
        }
        &-Header {
            background-color: #e73c37;
            height: 50px;
            padding: 0 15px;
            display: flex;
            align-items: center;
            p {
                margin: 0;
                letter-spacing: 1px;
                flex: 1;
            }
            .btn-close {
                background-color: transparent;
                border: none;
                font-size: 20px;
                color: white;
                &:disabled {
                    opacity: 0.8;
                }
            }
        
        }
        &-Content {
            padding: 25px 15px;
        }
        &-Controls {
            display: flex;
        }
    }
}


@keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  @-moz-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  @-webkit-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
