.EventView {
    position: fixed;
    inset: 0;
    z-index: 110;
    background-color: rgba(51, 51, 51, 0.7);
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
    animation: fadeIn .3s;
    -webkit-animation: fadeIn .3s;
    -moz-animation: fadeIn .3s;
    .device {
        width: 350px;
        height: 644px;
        overflow: hidden;
        position: relative;
        img {
            width: 100%;
            position: relative;
            z-index: 2;
        }
        .content-wrapper {
            position: absolute;
            z-index: 3;
            top: 60px;
            left: 30px;
            width: calc(100% - 60px);
            height: calc(100% - 120px);
        }
        .content {
            height: 100%;
            overflow: scroll;
            background-color: #333;
            &::-webkit-scrollbar {
                display: none;
            }
            -ms-overflow-style: none;
            scrollbar-width: none; 
            color: white;
            &-header {
                padding: 10px 20px;
                min-height: 60px;
                display: flex;
                align-items: center;
                p {
                    flex: 1;
                    font-family: 'SansaSlab-Black';
                    margin: 0;
                }
                .close-btn {
                    color: #7F848B;
                    font-size: 20px;
                    cursor: pointer;
                }
            }
            &-image {
                width: 100%;
                height: 250px;
                background-image: linear-gradient(#333, #e73c37);
                position: relative;
                overflow: hidden;
                img {
                    width: 100%;
                }
                .title {
                    font-size: 26px;
                    line-height: 34px;
                    font-family: 'SansaSlab-Black';
                    position: absolute;
                    z-index: 2;
                    margin: 0;
                    left: 20px;
                    bottom: 20px;
                    text-transform: uppercase;
                }
            }
            &-body {
                padding: 0 20px;
                .subtitle {
                    font-size: 20px;
                    line-height: 26px;
                    font-family: 'SansaSlab-Black';
                    text-transform: uppercase;
                }
                .description {
                    font-size: 16px;
                    line-height: 26px;
                    font-family: 'HelveticaNeue';
                }
                hr {
                    background-color: #404751;
                    height: 2px;
                    border: none;
                }
                .details {
                    .detail-name, .detail-value {
                        display: block;
                        font-family: 'HelveticaNeue';
                    }
                    .detail-name {
                        font-size: 12px;
                        font-weight: bold;
                        letter-spacing: 1px;
                        text-transform: uppercase;
                    }
                    .detail-value {
                        font-size: 16px;
                        line-height: 26px;
                        margin: 10px 0 15px;
                        &.link {
                            color: #FFDD00;
                        }
                    }
                }
            }
        }
    }
}