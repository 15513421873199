.Checkbox {
    margin-bottom: 10px;
    font-family: "Roboto-Condensed";
    align-self: flex-start;
    display: flex;
    align-items: center;
    &.reversed {
        flex-direction: row-reverse;
        .Checkbox-Text span {
            font-weight: normal;
            margin-right: 5px;
        }
    }
    &-Label {
      position: relative;
      display: flex;
      align-items: flex-start;
      font-size: 14px;
      cursor: pointer;
      input {
        -webkit-appearance: none;
        -moz-appearance: none;
        -o-appearance: none;
        appearance: none;
        width: 21px;
        min-width: 21px;
        height: 21px;
        background-color: transparent;
        border: 1px solid gray;
        border-radius: 3px;
        &:disabled {
            opacity: 0.4;
        }
        &.error {
          border: 1px solid red;
        }
      }
    }
    &-Text {
      span {
        color: #262626;
        font-weight: bold;
        margin-left: 5px;
        font-size: 14px;
        line-height: 25px;
      }
    }
    .disabled{
        span{
            opacity: 0.4;
        }
    }
    .Checkbox-Label > input:checked + span::before {
      content: '\2713';
      display: block;
      color: #262626;
      font-size: 18px;
      position: absolute;
      left: 0.5rem;
      top: 2px;
      font-weight: bold;
   
    }
  }