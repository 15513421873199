.New-Notification {
    margin: 15px auto;
    width: 95%;
    color: #333;
    &-Header {
        min-height: 50px;
        font-size: 24px;
        padding: 0 15px;
        border-bottom: 1px solid #dcddde;
        display: flex;
        align-items: center;
        @media (max-width: 767px) {
            padding: 0;
            font-size: 18px;
        }
    }
    &-Content {
        min-height: 300px;
        margin: 20px 0;
        border: 1px solid #dcddde;
        padding: 15px 15px 70px 15px;
        border-radius: 5px;
        position: relative;
        .recipients {
            min-height: 60px;
            display: flex;
            margin-bottom: 20px;
            border-bottom: 1px solid lightgray;
            padding-bottom: 10px;
            @media (max-width: 767px) {
                flex-direction: column;
            }
            .sendTo {
                font-weight: bold;
                display: inline-block;
                margin-top: 15px;
            }
            .Select {
                margin-left: 20px;
                margin-bottom: 0;
                flex: 1;
                @media (max-width: 767px) {
                    margin-left: 0;
                }
            }
            .Checkbox {
                margin-left: 20px;
                margin-top: 10px;
                @media (max-width: 767px) {
                    margin-left: 0;
                }
            }
        }
    
        .controls {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            display: flex;
            justify-content: flex-end;
            padding: 15px;
            button {
                margin: 0 0 0 10px;
                width: 100px;
            }
        }
    }
}