.Item-Image {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    &.list {
        margin-bottom: 0px;
        img {
            margin: 0 !important;
            overflow: hidden;
            padding: 0 !important;
        }
        .avatar {
            border-radius: 50%;
            overflow: hidden;
        }
    }
    .img-wrapper {
        width: 150px;
        height: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        .avatar {
            border-radius: 50%;
            overflow: hidden;
        }
        img {
            max-height: 100%;
            overflow: hidden;
        }
        @media (max-width: 767px) {
            width: 120px;
            height: 120px;
        }
    }
    .list-view-placeholder {
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        width: 50px;
        height: 50px;
        margin: auto;
        svg {
            width: 30px;
            height: 30px;
        }
    }
    .placeholder {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        background-color: lightgray;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 100px;
        margin-top: 10px;
        @media (max-width: 767px) {
            width: 120px;
            height: 120px;
            font-size: 80px;
        }
    }
}
