.Loader {
    display: flex;
    justify-content: center;
    &::after {
      animation: spin 2s linear infinite;
      align-self: center;
      box-sizing: border-box;
      border: 8px solid rgba(255, 255, 255, 0.5);
      border-top-color: white;
      border-radius: 50%;
      content: "";
      display: flex;
      width: 28px;
      height: 28px;
    }
    &.dark {
      &::after {
        border-color: rgba(231, 60, 55, 0.35);
        border-top-color:  #e73c37;
      }
    }
    &.big {
      &::after {
        width: 48px;
        height: 48px;
        border-width: 12px;
      }
    }
  }
  