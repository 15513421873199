.calendar-wrapper {
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    padding: 15px;
    @media (max-width: 768px) {
        min-height: unset;
        width: 90%;
        padding: 10px;
        justify-content: flex-start;
    }
    .rbc-calendar {
        width: 800px;
        height: 800px;
        @media (max-width: 768px) {
            width: 600px;
            height: 600px;
        }
        .rbc-toolbar {
            width: 40%;
            display: flex;
            flex-direction: row-reverse;
            position: relative;
            height: 40px;
            .rbc-btn-group {
                position: absolute;
                width: 100%;
                button {
                    border: none;
                    font-size: 30px;
                    margin: auto;
                    width: 20px;
                    overflow: hidden;
                    padding: 0;
                    font-weight: bold;
                    color: transparent;
                    &:hover {
                        background-color: transparent;
                        opacity: 0.8;
                    }
                    &:focus,
                    &:active {
                        background-color: transparent;
                        box-shadow: none;
                    }
                    &:first-child {
                        display: none;
                    }
                    &:nth-child(2) {
                        &::before {
                            content: ' \00AB';
                            color: #171717;
                        }
                    }
                    &:nth-child(3) {
                        position: absolute;
                        right: 0;
                        border-radius: 0;
                        &::before {
                            content: ' \00BB';
                            color: #171717;
                        }
                    }
                }
            }
            .rbc-toolbar-label {
                font-size: 20px;
                font-weight: bold;
            }
        }
        .rbc-month-view {
            .rbc-month-header {
                height: 40px;
                .rbc-header {
                    line-height: 40px;
                }
            }
            .rbc-today {
                background-color: transparent;
            }
            .rbc-event {
                background-color: #e73c37;
                height: 100%;
                padding: 0;
            }
            .rbc-row-content {
                display: flex;
                flex-direction: column;
                height: 100%;
                position: relative;
                overflow-y: scroll;
                &::-webkit-scrollbar {
                    display: none;
                }
                -ms-overflow-style: none;
                scrollbar-width: none;
                .rbc-row {
                    &:first-child {
                        position: sticky;
                        top: 0;
                        width: 100%;
                        z-index: 2;
                        height: 0;
                    }
                    .rbc-date-cell {
                        text-align: left;
                        a {
                            display: inline-block;
                            padding: 5px;
                        }
                    }
                    &:nth-child(2) {
                        flex: 1;
                    }
                }
            }
        }
        .rbc-event-content {
            height: 100%;
            padding-top: 20px;
            position: relative;
        }
        .Event {
            position: absolute;
            top: 0;
            margin: 0px 0 0 0;
            width: 100%;
            height: 100%;
            padding: 20px 5px 13px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            font-size: 12px;
            &.next {
                border: 3px solid yellow;
                border-radius: 5px;
            }
            &-Text {
                max-width: 100%;
                overflow: hidden;
            }
            &-Imgs {
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    max-width: 30%;
                    margin: 0 10%;
                }
            }
        }
    }
}
