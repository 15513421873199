.content-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    padding-top: 50px;
}

.charts-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.chart-holder {
    margin-top: 20px;
    display: flex;
}

.tooltip-wrapper {
    border: 2px solid rgb(218, 215, 215);
    border-radius: 5px;
    padding: 20px;
    background-color: rgba(137, 132, 217, 255);
    color: white;
    &.shop-item {
        background-color: (rgb(85, 175, 126));
        border: none;
    }
    &.scanned-tickets{
        background-color: rgb(71, 138, 226)
    }
    p {
        padding: 0;
        margin: 0;
    }
}

.custom-tooltip-pass {
    padding: 20px;
    background-color: red;
    color: white;
    border: 2px solid rgb(218, 215, 215);
    border-radius: 5px;
    display: block;
    p {
        margin: 0;
    }
    p:first-of-type {
        margin-bottom: 20px;
    }
}

.min-max_wrapper {
    min-width: 800px;
    display: flex;
    justify-content: space-evenly;

    div {
        margin-top: 50px;
        width: 170px;
        overflow: hidden;
        align-items: center;
        border-radius: 5px;
        color: white;
        P {
            margin-left: 20px;
        }
    }
    .pass-stats {
        background-color: rgba(137, 132, 217, 255);
    }
    .game-stats {
        background-color: red;
    }
}

.piechart-placeholder{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(230, 225, 225);
    border-radius: 20px;
    width: 600px;
    height: 200px;
}