.Lottery {
    margin: 15px auto;
    width: 95%;
    color: #333;
    &-Header {
        min-height: 50px;
        font-size: 24px;
        border-bottom: 1px solid #dcddde;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        @media (max-width: 767px) {
            padding: 0;
            font-size: 18px;
        }
        .controls {
            flex: 1;
            display: flex;
            justify-content: flex-end;
            button {
                &:first-child {
                    margin-right: 10px;
                }
            }
        }
    }
    &-Content {
        width: 100%;
        padding: 5px 15px 30px 15px;
        form {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: flex-start;
            @media (max-width: 767px) {
                flex-direction: column;
            }
            .Input, .Date-Picker, .Select, .Textarea, .Checkbox {
                width: 49%;
                @media (max-width: 767px) {
                    width: 100%;
                }
            }
            .row {
                width: 100%;
                .title {
                    font-size: 20px;  
                    font-weight: bold;
                    border-bottom: 1px solid lightgray;
                    padding-bottom: 5px;
                }
            }
        }
    }
}