.preview-screen-wrapper{
    position: absolute;
    left: 50%;
    right: 50%;
    transform: translate(-50%,-50%);
    display: flex;
    width: 40%;
    border: 1px solid black;
    border-radius: 10px;
    background-color: #000917;
    z-index: 1;
    flex-direction: column;
    color: white;
    bottom: -300px;
}
.preview-header{
    border-bottom: 1px solid black;
    height: 50px;
    width: 100%;
   
}

.preview-screen-title{
    color: white;
    display: flex;
    justify-content: space-between;
    padding: 15px;
    border-bottom: 1px solid gray;
    align-items: center;
    p{
        text-transform: uppercase;
        font-size: 18px;
        margin: 0;
        font-family: "Industry";
    }
    button{
        padding: 0;
        color: grey;
        background-color: transparent;
        border: none;
        transition: opacity ease-in-out .3s;
        &:hover{
            opacity: .9;
        }
        svg{

            height: 40px;
            width: 40px;
        }
    }
}
.preview-screen-content{
    margin-top: 20px;
    display: flex;
    justify-content: center;
    background-color: #20262f;
   img{
    width: 100%;
    max-height: 330px;
    padding: 30px;
    object-fit: contain;
   }
}

.preview-screen-description{
    padding: 15px;
    p{
        font-family: 'Industry';
    }
    .validation-date{
        margin-top: 40px;
    }
}

.preview-screen-action{
    padding: 15px;
    margin-bottom: 5px;
    button{
        border: 1px solid yellow;
        text-transform: uppercase;
        width: 100%;
        background-color: transparent;
        height: 50px;
        color: white;
        font-weight: bold;
        transition: opacity ease-in-out .3s;
        font-family: 'Industry';
        &:hover{
            opacity: .7;
        }
        letter-spacing: 1px;
    }
}

.preview-screen-menu{
    img{
        width: 100%;
    }
}

.voucher-detail-show{
    width: 80%;
    position: absolute;
    bottom: 55px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    overflow: hidden;
    background-color: white;

    .detail-header{
        width: 100%;
        padding: 15px;
        margin: auto;
        display: flex;
        justify-content: flex-end;
        position: absolute;
        button{
            background-color: transparent;
            border: none;
            color: rgb(66, 65, 65);
            svg{
                width: 40px;
                height: 40px;
            }
        }
    }

    .detail-content_external{
        display: flex;
        justify-content: center;
        img{
            width: 100%;
            max-height: 400px;
            object-fit: contain;
        }
    }

    .detail-controlls{
        height: 100%;
        display: flex;
        flex-direction: column;
        background-color: #e0e1e2;
        padding: 15px;
        color: black;
        text-transform: uppercase;
        font-family: 'Industry';
        font-weight: bold;
        p{
            text-align: center;
            margin: 5px 0;
            font-size: 17px;
            font-family: 'Industry';
        }
        span{
            text-align: left;
            font-size: 16px;
        }
        .copy-action{
            margin-top: 50px;
        }
        .copy-action, .link-action{
            width: 100%;
            display: flex;
            justify-content: center;
            button{
                display: flex;
                align-items: center;
                width: 100%;
                height: 50px;
                border: 1px solid red;
                padding: 0 40px;

                img{
                    width: 30px;
                    height: 30px;
                    margin-right: 5px;
                }
                p,a{
                    font-family: 'Industry';
                    text-transform: uppercase;
                    font-size: 17px;
                    margin: auto;
                }
                a{
                    text-overflow: ellipsis;
                    overflow: hidden;
                    text-align: center;
                }
            }
            padding: 5px 0;
        }
        .link-action{
            button{
                img{
                    width: 25px;
                    height: 25px;
                }
            }
        }
        .link-action-placeholder{
            height: 30px;
            width: 30px;
        }
    

    }

    .detail-content_eguma{
        background-color: white;
        flex-direction: column;
        height: 100%;
        ;
    }

    .preview-title{
        text-align: center;
        width: 60%;
        margin: 0 auto;
        padding-top: 50px;
        text-transform: uppercase;
        color: black;
        font-family: 'Industry';
        font-size: 20px;
    }

    .qr-code-desc{
        width: 70%;
        margin: 40px auto;
        text-align: center;
        font-family: "Industry";
        color: black;
    }
    
}

.qr-wrapper{
    display: flex;
    justify-content: center;
}

.code{
    text-align: center;
    font-family: 'Industry';
    margin: 25px 0 25px 0;
    font-size: 20px;
    color: black;
}

