.Fan-Games {
    position: relative;
    background-color: transparent;
    margin: 15px auto;
    width: 95%;
    color: #333;
    &-Header {
        min-height: 50px;
        font-size: 24px;
        padding: 0 15px;
        border-bottom: 1px solid #dcddde;
        display: flex;
        align-items: center;
        margin: 0;
        @media (max-width: 767px) {
            padding: 0;
            font-size: 18px;
        }
    }
    .game {
        display: flex;
        align-items: center;
        padding: 10px 15px;
        cursor: pointer;
        background-color: #f4f6f7;
        border-bottom: 1px solid #dcddde;
        height: 60px;
        &:nth-child(even) {
            background-color: transparent;
        }
        &:hover {
            background-color: #fbe9ea;
        }
    }
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
.refreshing{
    display: inline-block;
    animation: rotate 2s linear infinite; 
}

.solid, .refreshing{
    height: 20px;
    margin: 0 5px;
}
