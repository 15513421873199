.Fan {
    margin: 15px auto;
    width: 95%;
    color: #333;
    &-Header {
        min-height: 50px;
        font-size: 24px;
        padding: 0 15px;
        border-bottom: 1px solid #dcddde;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        @media (max-width: 767px) {
            padding: 0;
            font-size: 18px;
        }
    }
    .loader-wrapper {
        height: 100%;
        min-height: 700px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .no-itmes {
        margin: 15px;
        font-size: 18px;
        font-style: italic;
    }
    .header-controls{
        width: 100%;
        display: flex;
        justify-content: space-between;
       
    }
}
